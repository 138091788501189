<template>
	<div v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="hero-image">
			<slot name="heroContent" />
			<picture>
				<source
					v-if="headingImage.imagePortraitWebp"
					:srcset="headingImage.imagePortraitWebp"
					type="image/webp"
					media="(max-width: 500px)"
				/>
				<source
					v-if="headingImage.imagePortrait"
					:srcset="headingImage.imagePortrait"
					media="(max-width: 500px)"
				/>
				<source :srcset="headingImage.imageWebp" type="image/webp" />
				<source :srcset="headingImage.image" />
				<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
			</picture>
			<a v-if="route.name.startsWith('index')" class="scroll-down smooth-scroll" @click="scrollDown()" />
		</div>
	</div>
</template>

<script setup>
const route = useRoute();

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

defineProps({
	headingImage: { type: Object, default: () => {} },
});
</script>

<style lang="scss" scoped>
.hero-image {
	width: 100%;
	height: 100vh;
	position: relative;
}

.hero-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.subpage .hero-image {
	height: 50vh;
}

.hero-image::before {
	content: '';
	z-index: 2;
	position: absolute;
	display: block;
	inset: 0;
	background: rgba(0 0 0 / 20%);
}

.scroll-down {
	position: absolute;
	cursor: pointer;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: url('~/assets/images/icon-scroll-down.png') no-repeat center center;
	width: 48px;
	height: 48px;
	background-size: 48px;
	z-index: 2;
}
</style>
